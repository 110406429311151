import { lazy } from "react";
import "./App.css";
import AppThemeProvider from "AppThemeProvider";
import { Icon, IconButton } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { notistackRef } from "constants/RefConstants";
import Suspense from "common/Suspense";
import useAuthUser from "hooks/useAuthUser";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
function App() {
  const authUser = useAuthUser();
  const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });

   client.setClientRole('audience'); // Set the client role to 'host'

  return (
    <AppThemeProvider>
      <AgoraRTCProvider client={client}>
        <SnackbarProvider
          ref={notistackRef}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          preventDuplicate
          action={(key) => (
            <IconButton
              onClick={() => {
                notistackRef.current.closeSnackbar(key);
              }}
              color="inherit"
              size="small"
            >
              <Icon>close</Icon>
            </IconButton>
          )}
        >
          <Suspense>
            {
              // !!authUser?.accessToken ? <AppProtected /> :
              <AppPublic />
            }
          </Suspense>
        </SnackbarProvider>
      </AgoraRTCProvider>
    </AppThemeProvider>
  );
}

export default App;

const AppPublic = lazy(() => import("./AppPublic"));
const AppProtected = lazy(() => import("./AppProtected"));
